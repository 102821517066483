import React from "react";
import resumeFile from "../documents/resume.pdf";

const Resume = ({ classicHeader, darkTheme }) => {
  const educationDetails = [
    {
      yearRange: "2008 - 2018",
      title: "Primary Education",
      place: "St. Mary's High School",
      desc: "Completed my Primary education",
    },
    {
      yearRange: "2018 - 2020",
      title: "Higher Education",
      place: "Hansraj College",
      desc: "Completed my Higher education",
    },
    {
      yearRange: "2020 - 2024",
      title: "Bachelors on Computer Science",
      place: "Smt. Indira College of Engineering",
      desc: "Having completed my bachelor's in Computer Science from SIGCE, I concurrently honed my skills through job opportunities, balancing academia with practical experience to refine my expertise. ",
    },
  ];

  const experienceDetails = [
    {
      yearRange: "2024 - Present",
      title: "Freelancing",
      place: "Freelancing",
      desc: "As a freelancer, I diversified my portfolio by developing NFT games, mini PC games, health apps, women empowerment apps, a learning management system, and an ID card manager. This broadened my exposure across multiple industries while showcasing my adaptability and innovation in delivering tailored solutions to clients.",
    },
    {
      yearRange: "2019 - 2023",
      title: "Sr. Product Engineer",
      place: "Kirsh Games",
      desc: "As a Senior Software Engineer at Kirsh Games, I contributed to developing groundbreaking products spanning finance, gaming, and machine learning domains. Our achievements included an end-to-end online broking system, advanced algorithmic trading platforms, and captivating mobile games, showcasing our team's versatility and innovation in creating scalable solutions. At Kirsh Games, our small but dedicated team consistently pushed the boundaries, delivering impactful technologies that left a mark in the industry.",
    },
  ];

  const skills = [
    {
      name: "Golang",
      percent: 95,
    },
    {
      name: "Python",
      percent: 80,
    },
    {
      name: "JavaScript",
      percent: 80,
    },
    {
      name: "Angular Dart",
      percent: 90,
    },
    {
      name: "NextJS",
      percent: 80,
    },
    {
      name: "Php",
      percent: 73,
    },
    {
      name: "Flutter",
      percent: 84,
    },
    {
      name: "Java",
      percent: 70,
    },
    {
      name: "Bootstrap",
      percent: 85,
    },
    {
      name: "Tailwind",
      percent: 75,
    },
  ];

  return (
    <section
      id="resume"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Summary
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Resume
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gx-5">
          {/* My Education */}
          <div className="col-md-6">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              My Education
            </h2>
            {educationDetails.length > 0 &&
              educationDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                    {value.title}
                  </h3>
                  <p className={darkTheme ? "text-primary" : "text-danger"}>
                    {value.place}
                  </p>
                  <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                    {value.desc}
                  </p>
                </div>
              ))}
          </div>
          {/* My Experience */}
          <div className="col-md-6">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              My Experience
            </h2>
            {experienceDetails.length > 0 &&
              experienceDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                    {value.title}
                  </h3>
                  <p className={darkTheme ? "text-primary" : "text-danger"}>
                    {value.place}
                  </p>
                  <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                    {value.desc}
                  </p>
                </div>
              ))}
          </div>
        </div>
        {/* My Skills */}
        <h2
          className={
            "text-6 fw-600 mt-4 mb-4 " + (darkTheme ? "text-white" : "")
          }
        >
          My Core Skills
        </h2>
        <div className="row gx-5">
          {skills.length > 0 &&
            skills.map((skill, index) => (
              <div key={index} className="col-md-6">
                <p
                  className={
                    " fw-500 text-start mb-2 " +
                    (darkTheme ? "text-light" : "text-dark")
                  }
                >
                  {skill.name}{" "}
                  <span className="float-end">{skill.percent}%</span>
                </p>
                <div
                  className={
                    "progress progress-sm mb-4 " + (darkTheme ? "bg-dark" : "")
                  }
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.percent + "%" }}
                    aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div>
        <div className="text-center mt-5">
          <a
            className="btn btn-outline-secondary rounded-pill shadow-none"
            href={resumeFile}
            download
          >
            Download CV
            <span className="ms-1">
              <i className="fas fa-download" />
            </span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Resume;
